import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Layout, Section, Carousel, EventCardList, ArtCard } from 'components';
import { SpaceInfo, Hours } from 'containers';
import { Alert } from 'react-uikit3';
import { GrClose } from 'react-icons/gr';
import Markdown from 'react-markdown';

const IndexPage = (props) => {
  const data = useStaticQuery(query);
  return (
    <Layout seo={data.strapiHomepage.seo}>
      <Carousel imgList={data.strapiHomepage.FeaturedImages} />
      {data.strapiNotice.Title || data.strapiNotice.Message ? (
        <Alert className='uk-alert-primary uk-padding uk-margin-remove'>
          <div className='uk-h5 uk-text-primary uk-text-bold'>
            {data.strapiNotice.Title}
          </div>
          <div className='uk-h6 uk-text-primary uk-margin-small-right uk-margin-small-top'>
            <Markdown source={data.strapiNotice.Message} escapeHtml={false} />
          </div>
          <a className='uk-alert-close'>
            <GrClose />
          </a>
        </Alert>
      ) : null}
      <SpaceInfo spaces={data} />
      <Section title='전시 미술'>
        <ArtCard data={data.allStrapiArts.nodes[0]} />
      </Section>
      {data.allStrapiEvents.nodes[0] ? (
        <Section title='진행중인 이벤트'>
          <EventCardList events={data.allStrapiEvents} />
        </Section>
      ) : null}
      <div className='uk-background-muted'>
        <Hours data={data.strapiHomepage} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    strapiNotice {
      Title
      Message
    }
    strapiHomepage {
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      cafesori_hours
      hwaban_hours
      oolim_hours
      sorakcc_hours
      notice
      FeaturedImages {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
      Images {
        cafesori_img_small_1 {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                aspectRatio: 1
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
        }
        cafesori_img_small_2 {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                aspectRatio: 1
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
        }
        cafesori_img_wide {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                aspectRatio: 2.05
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
        }
        hwaban_img_small_1 {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                aspectRatio: 1
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
        }
        hwaban_img_small_2 {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                aspectRatio: 1
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
        }
        hwaban_img_wide {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                aspectRatio: 2.05
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
        }
        oolim_img_small_1 {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                aspectRatio: 1
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
        }
        oolim_img_small_2 {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                aspectRatio: 1
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
        }
        oolim_img_wide {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                aspectRatio: 2.05
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
        }
        sanchek_img_small_1 {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                aspectRatio: 1
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
        }
        sanchek_img_small_2 {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                aspectRatio: 1
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
        }
        sanchek_img_wide {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                aspectRatio: 2.05
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
        }
      }
    }
    allStrapiArts(filter: { main_page: { eq: true } }, limit: 1) {
      nodes {
        artist
        date
        description
        medium
        title
        main_page
        size
        art {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
    allStrapiEvents(
      filter: {
        type: { eq: "event" }
        status: { eq: "now" }
        main_page: { eq: true }
      }
      limit: 1
      sort: { order: DESC, fields: date }
    ) {
      nodes {
        title
        description
        type
        short_description
        slug
        date
        id
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                height: 700
                aspectRatio: 0.72
                transformOptions: { fit: FILL }
              )
            }
          }
        }
        location {
          title
          slug
        }
      }
    }
  }
`;

export default IndexPage;
